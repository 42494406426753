export default function contenido() {
  return 0
}

export function getContenidos(state) {
  return state.contenidos
}

export function getContenidosMensual(state) {
  return state.contenidosMensual
}

export function getTipoContenido(state) {
  return state.tipoContenido
}

export function getContenidosActivos(state) {
  return state.contenidosActivos
}

export function getContenidoSelected(state) {
  return state.selectedContenido
}
