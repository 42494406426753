export function setFirma(state, firma) {
  state.selectedFirma = firma
}

export function setResponse(state, payload) {
  state.status = payload.status
  state.message = payload.message
}

export function firmaError(state, payload) {
  state.error = true
  state.errorMessage = payload
  state.firmas = []
}

export function firmaErrorNull(state) {
  state.error = false
  state.errorMessage = []
}
