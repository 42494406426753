import axios from 'axios'

export default async function reuniones() {
  return 0
}

export async function fetchReunion({ commit }, id_reunion) {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `parcial/${id_reunion}`,
    })
    commit('setReunion', data)
  } catch (e) {
    commit('parcialError', e.response.data)
  }
}

export async function fetchReuniones({ commit }, id_curso) {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `reunionapoderado/${id_curso}`,
    })
    commit('setReuniones', data)
  } catch (e) {
    console.log('error :', e)
    // commit('reunionError', e.response.data)
  }
}

export async function addReunion({ commit }, datos) {
  try {
    // commit('reunionErrorNull')
    const {
      id_curso,
      fecha,
      hora,
      acuerdos,
      observaciones,
      alumnos,
    } = datos
    console.log('datos create:', datos)
    const { data } = await axios({
      method: 'POST',
      url: 'reunionapoderado',
      data: {
        id_curso,
        fecha,
        hora,
        acuerdos,
        observaciones,
        alumnos,
      },
    })
    commit('setResponse', data)
  } catch (e) {
    console.log('error :', e)
    // commit('parcialError', e.response.data)
  }
}

export async function updateReunion({ commit }, datos) {
  try {
    // commit('parcialErrorNull')
    const {
      id,
      fecha,
      hora,
      acuerdos,
      observaciones,
      id_curso,
      alumnos,
    } = datos
    console.log('datos update:', datos)
    const {data} = await axios({
      method: 'PUT',
      url: `reunionapoderado/${id}`,
      data: {
        id_curso,
        fecha,
        hora,
        acuerdos,
        observaciones,
        alumnos,
      },
    })
    commit('setResponse', data)
  } catch (e) {
    console.log('error :', e)
    // commit('parcialError', e.response.data)
  }
}

export async function removeReunion({ commit, dispatch }, datos) {
  try {
    const {
      id,
      id_curso,
    } = datos
    const { data } = await axios({
      method: 'DELETE',
      url: `/reunionapoderado/${id}`,
    })
    dispatch('fetchReuniones', id_curso)
  } catch (e) {
    console.log('error remove :', e)
    // commit('observacionError', e.response.data)
  }
}

