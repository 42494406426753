import store from '@/store'

export default [
  {
    path: '/cursos',
    name: 'cursos',
    component: () => import('@/views/Mantenedores/Cursos/Cursos.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'login',
        })
      }
      return next()
    },
    meta: {
      pageTitle: 'Cursos',
      breadcrumb: [
        {
          text: 'Cursos',
          active: true,
        },
      ],
    },
  },
  {
    path: '/cursos-create',
    name: 'cursos-create',
    component: () => import('@/views/Mantenedores/Cursos/CursosCreate.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'login',
        })
      }
      return next()
    },
    meta: {
      pageTitle: 'Crear Curso',
      breadcrumb: [
        {
          text: 'Cursos',
          to: '/cursos',
        },
        {
          text: 'Crear Curso',
          active: true,
        },
      ],
    },
  },
  {
    path: '/cursos/update',
    name: 'cursos-update',
    component: () => import('@/views/Mantenedores/Cursos/CursosUpdate.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'login',
        })
      }
      return next()
    },
    meta: {
      pageTitle: 'Editar curso',
      breadcrumb: [
        {
          text: 'Cursos',
          to: '/cursos',
        },
        {
          text: 'Editar Curso',
          active: true,
        },
      ],
    },
  },
]