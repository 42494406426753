export default function curso() {
  return 0
}

export function getCurso(state) {
  return state.curso
}

export function getCursos(state) {
  return state.cursos
}

export function getCursosPersonaRol(state) {
  return state.cursosPersonaRol
}

export function getCursosActivos(state) {
  return state.cursosActivos
}
