import axios from 'axios'

export default async function cursos() {
  return 0
}

export async function fetchCurso({ commit }, id_curso) {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `curso/${id_curso}`,
    })
    commit('setCurso', data)
  } catch (e) {
    commit('cursoError', e.response.data)
  }
}

export async function fetchCursos({ commit }) {
  try {
    const { data } = await axios.get('curso')
    commit('setCursos', data)
  } catch (e) {
    commit('cursoError', e.response.data)
  }
}

export async function fetchCursosJefatura({ commit }) {
  try {
    const { data } = await axios.get('cursos/docentejefe')
    commit('setCursos', data)
  } catch (e) {
    commit('cursoError', e.response.data)
  }
}

export async function fetchCursosEstablecimiento({ commit }, id_establecimiento) {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `cursoEstablecimiento/${id_establecimiento}`,
    })

    commit('setCursos', data)
  } catch (e) {
    commit('cursoError', e.response.data)
  }
}

export async function fetchCursosPersonaRol({ commit }) {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `cursospersonarol`,
    })
    commit('setCursosPersonaRol', data)
  } catch (e) {
    commit('cursoError', e.response.data)
  }
}

export async function addCursoPersona({ commit, dispatch }, datos) {
  try {
    commit('cursoErrorNull')

    const {
      id_persona_rol,
      id_cursos,
      pie,
    } = datos

    const { data } = await axios({
      method: 'POST',
      url: 'cursopersona',
      data: {
        id_persona_rol,
        id_cursos,
        pie,
      },
    })

    commit('setResponse', data)
    dispatch('fetchCursosPersonaRol')
  } catch (e) {
    commit('cursoError', e.response.data)
  }
}

export async function addCurso({ commit }, curso) {
  try {
    commit('cursoErrorNull')

    const { data } = await axios({
      method: 'POST',
      url: 'curso',
      data: {
        id_calendario: curso.id_calendario,
        id_codigo_grado: curso.id_codigo_grado,
        letra: curso.letra,
        total_alumnos: curso.total_alumnos,
        combinado: curso.combinado,
        tipo_jornada: curso.tipo_jornada,
        id_especialidad: curso.id_especialidad,
        alternativa: curso.alternativa,
        infraestructura: curso.infraestructura,
      },
    })

    commit('setResponse', data)
  } catch (e) {
    commit('cursoError', e.response.data)
  }
}

export async function addProfesorJefe({ commit }, datos) {
  try {
    const { data } = await axios({
      method: 'POST',
      url: 'docentejefe',
      data: {
        id_curso: datos.id_curso,
        id_persona_rol: datos.id_persona_rol_jefe,
      },
    })
    commit('setResponse', data)
  } catch (e) {
    commit('cursoError', e.response.data)
  }
}


export async function ordenarLista({ commit }, lista) {
  try {
    const { data } = await axios({
      method: 'POST',
      url: 'ordenlista',
      data: {
        id_curso: lista.id_curso,
        alumnos: lista.alumnos,
      },
    })
    commit('setResponse', data)
  } catch (e) {
    commit('cursoError', e.response.data)
  }
}

export async function updateCurso({ commit }, curso) {
  try {
    commit('cursoErrorNull')
    const response = await axios({
      method: 'PUT',
      url: `curso/${curso.id}`,
      data: {
        id_calendario: curso.id_calendario,
        id_codigo_grado: curso.id_codigo_grado,
        letra: curso.letra,
        total_alumnos: curso.total_alumnos,
        combinado: curso.combinado,
        tipo_jornada: curso.tipo_jornada,
        id_especialidad: curso.id_especialidad,
        alternativa: curso.alternativa,
        infraestructura: curso.infraestructura,
      },
    })
  } catch (e) {
    commit('cursoError', e.response.data)
  }
}

export async function removeCursos({ commit, dispatch }, id) {
  try {
    await axios({
      method: 'DELETE',
      url: `/cursos/${id}`,
    })
    // actualizamos lista de cursos
    dispatch('fetchCursos')
  } catch (e) {
    commit('cursoError', e.response.data)
  }
}

export async function removeCursoPersona({ commit, dispatch }, id_curso_rol) {
  try {
    const {data} = await axios({
      method: 'DELETE',
      url: `/cursopersona/${id_curso_rol}`,
    })
    // actualizamos lista de cursos
    dispatch('fetchCursosPersonaRol')
  } catch (e) {
    commit('cursoError', e.response.data)
  }
}
