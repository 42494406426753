export default async function configuracionInicial() {
  return 0
}

export async function activaConfiguracionInicial({ commit }) {
  commit('setConfiguracionInicial', true)
}

export async function desactivaConfiguracionInicial({ commit }) {
  commit('setConfiguracionInicial', false)
}
