import axios from 'axios'

export default async function informes() {
  return 0
}

export async function fetchObservacion({ commit }, id_parcial) {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `parcial/${id_parcial}`,
    })
    commit('setParcial', data)
  } catch (e) {
    commit('parcialError', e.response.data)
  }
}

export async function fetchObservaciones({ commit }, datos) {
  try {
    const {
      id_curso,
      tipo,
      id_periodo,
    } = datos
    const { data } = await axios({
      method: 'GET',
      url: `observaciones/${id_curso}/${tipo}/${id_periodo}`,
    })
    commit('setObservaciones', data)
  } catch (e) {
    commit('parcialError', e.response.data)
  }
}

export async function addObservacion({ commit }, datos) {
  try {
    commit('parcialErrorNull')
    const {
      observacion,
      tipo,
      id_periodo,
      id_persona_rol_alumno,
      dias_trabajados,
      dias_asistencia,
      dias_inasistencia,
      porcentaje_asistencia,
    } = datos
    const { data } = await axios({
      method: 'POST',
      url: 'observacion',
      data: {
        observacion,
        tipo,
        id_periodo,
        id_persona_rol_alumno,
        dias_trabajados,
        dias_asistencia,
        dias_inasistencia,
        porcentaje_asistencia,
      },
    })
    commit('setResponse', data)
  } catch (e) {
    commit('parcialError', e.response.data)
  }
}

export async function updateObservacion({ commit }, datos) {
  try {
    commit('parcialErrorNull')
    const {
      id,
      observacion,
      tipo,
      id_periodo,
      id_persona_rol_alumno,
      dias_trabajados,
      dias_asistencia,
      dias_inasistencia,
      porcentaje_asistencia,
    } = datos
    const {data} = await axios({
      method: 'PUT',
      url: `observacion/${id}`,
      data: {
        observacion,
        tipo,
        id_periodo,
        id_persona_rol_alumno,
        dias_trabajados,
        dias_asistencia,
        dias_inasistencia,
        porcentaje_asistencia,
      },
    })
    commit('setResponse', data)
  } catch (e) {
    commit('parcialError', e.response.data)
  }
}
