import store from '@/store'

export default [
  {
    path: '/plantillas-personalidad',
    name: 'plantillas-personalidad',
    component: () => import('@/views/Mantenedores/Plantillas/PlantillasPersonalidad.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'login',
        })
      }
      return next()
    },
    meta: {
      pageTitle: 'Plantillas informe de personalidad',
      breadcrumb: [
        {
          text: 'Plantillas informe de personalidad',
          active: true,
        },
      ],
    },
  },
  {
    path: '/personalidad-create',
    name: 'personalidad-create',
    component: () => import('@/views/Mantenedores/Plantillas/components/Personalidad/PersonalidadCreate.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'login',
        })
      }
      return next()
    },
    meta: {
      pageTitle: 'Crear Plantilla Informe De Personalidad',
      breadcrumb: [
        {
          text: 'Plantillas informe de personalidad',
          to: '/plantilla-personalidad',
        },
        {
          text: 'Crear Plantilla informe de personalidad',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/cursos/update',
  //   name: 'cursos-update',
  //   component: () => import('@/views/Mantenedores/Cursos/components/CursosUpdate.vue'),
  //   beforeEnter: (to, from, next) => {
  //     if (!store.getters['auth/authenticated']) {
  //       return next({
  //         name: 'login',
  //       })
  //     }
  //     return next()
  //   },
  //   meta: {
  //     pageTitle: 'Editar curso',
  //     breadcrumb: [
  //       {
  //         text: 'Cursos',
  //         to: '/cursos',
  //       },
  //       {
  //         text: 'Editar Curso',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
]