export function setContenidos(state, contenidos) {
  state.contenidos = contenidos
}

export function setContenidosActivos(state, contenidos) {
  state.contenidosActivos = contenidos
}

export function setContenido(state, contenido) {
  state.selectedContenido = contenido
}

export function setDataResponse(state, payload) {
  state.id_fecha_curso = payload.id_fecha_curso
  state.id_contenido = payload.id_contenido
}

export function setResponse(state, payload) {
  state.status = payload.status
  state.message = payload.message
}

export function setContenidosMensual(state, contenidosMensual) {
  state.contenidosMensual = contenidosMensual
}

export function setTipoContenido(state, tipoContenido) {
  state.tipoContenido = tipoContenido
}

export function updateContenidoEstado(state, payload) {
  const contenido = state.contenidos.find(u => u.id === payload.id)
  if (contenido) {
    state.done = !contenido.done
  }
}

export function contenidoError(state, payload) {
  state.error = true
  state.errorMessage = payload
  state.contenidos = []
}

export function contenidoErrorNull(state) {
  state.error = false
  state.errorMessage = []
}
