export function setObservaciones(state, observaciones) {
  state.observaciones = observaciones
}

export function setObservacionesActivos(state, observaciones) {
  state.observacionesActivos = observaciones
}

export function setObservacion(state, observacion) {
  state.selectedObservacion = observacion
}

export function setObservacionesAsignatura(state, observaciones) {
  state.observacionesAsignatura = observaciones
}

export function updateObservacionEstado(state, payload) {
  const observacion = state.observaciones.find(u => u.id === payload.id)
  if (observacion) {
    state.done = !observacion.done
  }
}

export function setResponse(state, payload) {
  state.status = payload.status
  state.message = payload.message
}

export function observacionError(state, payload) {
  state.error = true
  state.errorMessage = payload
  state.observaciones = []
}

export function observacionErrorNull(state) {
  state.error = false
  state.errorMessage = []
}
