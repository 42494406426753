export default {
  notas: [],
  notasActivos: [],
  selectedLibro: null,
  message: null,
  status: null,
  datos_estudiante: null,
  id_nota: null,
  error: false,
  errorMessage: [],
}
