export function setConfiguraciones(state, configuraciones) {
  state.configuraciones = configuraciones
}

export function setResponse(state, payload) {
  state.status = payload.status
  state.message = payload.message
}

export function configuracionError(state, payload) {
  state.error = true
  state.errorMessage = payload
  state.configuraciones = []
}

export function configuracionErrorNull(state) {
  state.error = false
  state.errorMessage = []
}
