export default function personalidad() {
  return 0
}

export function getPersonalidad(state) {
  return state.personalidad
}

export function getPersonalidades(state) {
  return state.personalidades
}

export function getPersonalidadGrado(state) {
  return state.personalidadGrado
}

export function getPersonalidadesActivos(state) {
  return state.personalidadesActivos
}
