export function setPersonalidades(state, personalidades) {
  state.personalidades = personalidades
}

export function setPersonalidadesPersonaRol(state, personalidadesPersonaRol) {
  state.personalidadesPersonaRol = personalidadesPersonaRol
}

export function setPersonalidadesActivos(state, personalidades) {
  state.personalidadesActivos = personalidades
}

export function setPersonalidadSelected(state, personalidad) {
  state.selectedPersonalidad = personalidad
}

export function setPersonalidad(state, personalidad) {
  state.personalidad = personalidad
}

export function setPersonalidadGrado(state, personalidadGrado) {
  state.personalidadGrado = personalidadGrado
}

export function setResponse(state, payload) {
  state.status = payload.status
  state.message = payload.message
}

export function updatePersonalidadEstado(state, payload) {
  const personalidad = state.personalidades.find(u => u.id === payload.id)
  if (personalidad) {
    state.done = !personalidad.done
  }
}

export function personalidadError(state, payload) {
  state.error = true
  state.errorMessage = payload
  state.personalidades = []
}

export function personalidadErrorNull(state) {
  state.error = false
  state.errorMessage = []
}
