export default function reemplazo() {
  return 0
}

export function getReemplazos(state) {
  return state.reemplazos
}

// export function getEstablecimientosActivos(state) {
//   return state.establecimientosActivos
// }
