import axios from 'axios'

export default async function evaluaciones() {
  return 0
}

export async function fetchEvaluaciones({ commit }, id_asignatura) {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `evaluacion/${id_asignatura}`,
    })
    commit('setEvaluaciones', data)
  } catch (e) {
    commit('evaluacionError', e.response.data)
  }
}

export async function fetchEvaluacionesSumativas({ commit }, id_asignatura) {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `evaluacionsumativa/${id_asignatura}`,
    })
    commit('setEvaluacionesSumativas', data)
  } catch (e) {
    commit('evaluacionError', e.response.data)
  }
}

export async function fetchEvaluacionesParaSumativa({ commit }, id_asignatura) {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `evaluaciones/${id_asignatura}`,
    })
    commit('setEvaluacionesParaSumativa', data)
  } catch (e) {
    commit('evaluacionError', e.response.data)
  }
}

export async function addEvaluacion({ commit }, evaluacion) {
  try {
    commit('evaluacionErrorNull')
    const { data } = await axios({
      method: 'POST',
      url: 'evaluacion',
      data: {
        fecha: evaluacion.fecha,
        tipo: evaluacion.tipo,
        nombre: evaluacion.nombre,
        descripcion: evaluacion.descripcion,
        orden: evaluacion.orden,
        parcial: evaluacion.parcial,
        porcentaje: evaluacion.porcentaje,
        id_asignatura: evaluacion.id_asignatura,
        id_periodo: evaluacion.id_periodo,
      },
    })
    commit('setResponse', data)
  } catch (e) {
    commit('evaluacionError', e.response.data)
  }
}

export async function updateEvaluacion({ commit }, evaluacion) {
  try {
    commit('evaluacionErrorNull')
    const { data } = await axios({
      method: 'PUT',
      url: `evaluacion/${evaluacion.id}`,
      data: {
        fecha: evaluacion.fecha,
        tipo: evaluacion.tipo,
        nombre: evaluacion.nombre,
        descripcion: evaluacion.descripcion,
        orden: evaluacion.orden,
        parcial: evaluacion.parcial,
        porcentaje: evaluacion.porcentaje,
        id_asignatura: evaluacion.id_asignatura,
        id_periodo: evaluacion.id_periodo,
      },
    })

    commit('setResponse', data)
  } catch (e) {
    commit('evaluacionError', e.response.data)
  }
}

export async function removeEvaluaciones({ commit, dispatch }, evaluacion) {
  try {
    const {data} = await axios({
      method: 'DELETE',
      url: `/evaluacion/${evaluacion.id}`,
    })
    // actualizamos lista de evaluaciones
    dispatch('fetchEvaluaciones', evaluacion.id_asignatura)
  } catch (e) {
    commit('evaluacionError', e.response.data)
  }
}

export async function addEvaluacionSumativa({ commit }, evaluacion) {
  try {
    commit('evaluacionErrorNull')
    const { data } = await axios({
      method: 'POST',
      url: 'evaluacionsumativa',
      data: {
        fecha: evaluacion.fecha,
        nombre: evaluacion.nombre,
        descripcion: evaluacion.descripcion,
        orden: evaluacion.orden,
        id_evaluacion: evaluacion.id_evaluacion,
        id_asignatura: evaluacion.id_asignatura,
        id_periodo: evaluacion.id_periodo,
      },
    })
    commit('setResponse', data)
  } catch (e) {
    commit('evaluacionError', e.response.data)
  }
}

export async function updateEvaluacionSumativa({ commit }, evaluacion) {
  try {
    commit('evaluacionErrorNull')
    const { data } = await axios({
      method: 'PUT',
      url: `evaluacionsumativa/${evaluacion.id}`,
      data: {
        fecha: evaluacion.fecha,
        nombre: evaluacion.nombre,
        descripcion: evaluacion.descripcion,
        orden: evaluacion.orden,
        id_evaluacion: evaluacion.id_evaluacion,
        id_asignatura: evaluacion.id_asignatura,
        id_periodo: evaluacion.id_periodo,
      },
    })

    commit('setResponse', data)
  } catch (e) {
    commit('evaluacionError', e.response.data)
  }
}

export async function removeEvaluacionesSumativa({ commit, dispatch }, evaluacion) {
  try {
    const {data} = await axios({
      method: 'DELETE',
      url: `/evaluacionsumativa/${evaluacion.id}`,
    })
    console.log(data)
    // actualizamos lista de evaluaciones
    dispatch('fetchEvaluacionesSumativas', evaluacion.id_asignatura)
  } catch (e) {
    commit('evaluacionError', e.response.data)
  }
}