import store from '@/store'

export default [
  {
    path: '/mis-horarios',
    name: 'mis-horarios',
    component: () => import('@/views/MisHorarios/Horarios.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'login',
        })
      }
      return next()
    },
    meta: {
      pageTitle: 'Mis Horarios',
      breadcrumb: [
        {
          text: 'Mis Horarios',
          active: true,
        },
      ],
    },
  },
  {
    path: '/horarios-create',
    name: 'horarios-create',
    component: () => import('@/views/MisHorarios/HorariosCreate.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'login',
        })
      }
      return next()
    },
    meta: {
      pageTitle: 'Crear Horario',
      breadcrumb: [
        {
          text: 'Mis Horarios',
          to: '/mis-horarios',
        },
        {
          text: 'Crear Horario',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mis-horarios/update',
    name: 'mis-horarios-update',
    component: () => import('@/views/MisHorarios/HorariosUpdate.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'login',
        })
      }
      return next()
    },
    meta: {
      pageTitle: 'Editar Horario',
      breadcrumb: [
        {
          text: 'Mis Horarios',
          to: '/mis-horarios',
        },
        {
          text: 'Editar Horario',
          active: true,
        },
      ],
    },
  },
]