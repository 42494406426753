export default function establecimiento() {
  return 0
}

export function getEstablecimientos(state) {
  return state.establecimientos
}

export function getAproximaciones(state) {
  return state.aproximaciones
}

export function getSelectedEstablecimiento(state) {
  return state.selectedEstablecimiento
}
