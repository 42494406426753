export default {
  asignaturaConfig: [],
  // CONFIGS
  loading: null,
  response: {
    status: false,
    message: '',
  },
  error: false,
  errorMessage: [],
}
