import axios from 'axios'

export default async function comunicaciones() {
  return 0
}

export async function fetchComunicacion({ commit }, id_comunicacion) {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `parcial/${id_comunicacion}`,
    })
    commit('setComunicacion', data)
  } catch (e) {
    commit('parcialError', e.response.data)
  }
}

export async function fetchComunicaciones({ commit }) {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `comunicaciones`,
    })
    commit('setComunicaciones', data)
  } catch (e) {
    console.log('error :', e)
    // commit('comunicacionError', e.response.data)
  }
}

export async function addComunicacion({ commit }, datos) {
  try {
    // commit('comunicacionErrorNull')
    const {
      tipo,
      titulo,
      detalle,
      fecha_envio,
      hora_envio,
      estado,
      cursos,
      alumnos,
    } = datos
    const { data } = await axios({
      method: 'POST',
      url: 'comunicaciones',
      data: {
        tipo,
        titulo,
        detalle,
        fecha_envio,
        hora_envio,
        estado,
        cursos,
        alumnos,
      },
    })
    commit('setResponse', data)
  } catch (e) {
    console.log('error :', e)
    // commit('parcialError', e.response.data)
  }
}

export async function updateComunicacion({ commit }, datos) {
  try {
    // commit('parcialErrorNull')
    const {
      id,
      fecha,
      hora,
      acuerdos,
      observaciones,
      id_curso,
      alumnos,
    } = datos
    console.log('datos update:', datos)
    const {data} = await axios({
      method: 'PUT',
      url: `comunicacionapoderado/${id}`,
      data: {
        id_curso,
        fecha,
        hora,
        acuerdos,
        observaciones,
        alumnos,
      },
    })
    commit('setResponse', data)
  } catch (e) {
    console.log('error :', e)
    // commit('parcialError', e.response.data)
  }
}

export async function removeComunicacion({ commit, dispatch }, datos) {
  try {
    const {
      id,
      id_curso,
    } = datos
    const { data } = await axios({
      method: 'DELETE',
      url: `/comunicacionapoderado/${id}`,
    })
    dispatch('fetchComunicaciones', id_curso)
  } catch (e) {
    console.log('error remove :', e)
    // commit('observacionError', e.response.data)
  }
}

