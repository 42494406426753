export default {
  asignaturas: [],
  optionsTiposAsignatura: [
    {
      value: 1,
      text: 'Normal'
    },
    {
      value: 3,
      text: 'Taller'
    },
    {
      value: 4,
      text: 'Evaluada Por Conceptos'
    },
    {
      value: 5,
      text: 'Compuesta'
    },
    {
      value: 2,
      text: 'Pertenece a compuesta'
    },
  ],
  asignaturasCurso: [],
  asignaturasActivos: [],
  selectedAsignatura: null,
  // CONFIGS
  loading: null,
  response: {
    status: false,
    message: '',
  },
  error: false,
  errorMessage: [],
}
