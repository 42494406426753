export default function firma() {
  return 0
}

export function getFirmas(state) {
  return state.firmas
}

export function getFirmaSelected(state) {
  return state.selectedFirma
}

export function getResponse(state) {
  const data = {
    status: state.status,
    message: state.message,
  }
  return data
}
