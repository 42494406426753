import axios from 'axios'

export default async function observaciones() {
  return 0
}

export async function fetchObservacionesEstudiante({ commit, dispatch }, id_persona_rol_estudiante) {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `hojavida/${id_persona_rol_estudiante}`,
    })
    commit('setObservaciones', data)
  } catch (e) {
    commit('observacionError', e.response.data)
  }
}

export async function fetchObservacionesAsignatura({ commit, dispatch }, id_asignatura) {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `hojavidaasignatura/${id_asignatura}`,
    })
    commit('setObservacionesAsignatura', data)
  } catch (e) {
    commit('observacionError', e.response.data)
  }
}

export async function addObservacion({ commit, dispatch }, observacion) {
  try {
    commit('observacionErrorNull')
    const { data } = await axios({
      method: 'POST',
      url: `hojavida`,
      data: {
        fecha: observacion.fecha,
        tipo: observacion.tipo,
        observacion: observacion.observacion,
        id_asignatura: observacion.id_asignatura,
        alumnos: observacion.alumnos,
      },
    })
    dispatch('fetchObservacionesAsignatura', observacion.id_asignatura)
    commit('setResponse', data)
  } catch (e) {
    commit('observacionError', e.response.data)
  }
}

export async function updateObservacion({ commit, dispatch }, observacion) {
  try {
    commit('observacionErrorNull')
    const { data } = await axios({
      method: 'PUT',
      url: `hojavida/${observacion.id_observacion}`,
      data: {
        fecha: observacion.fecha,
        tipo: observacion.tipo,
        observacion: observacion.observacion,
        id_asignatura: observacion.id_asignatura,
        id_persona_rol_estudiante: observacion.id_persona_rol_estudiante,
      },
    })
    dispatch('fetchObservacionesAsignatura', observacion.id_asignatura)
    commit('setResponse', data)
  } catch (e) {
    commit('observacionError', e.response.data)
  }
}

export async function removeObservacion({ commit, dispatch }, datos) {
  try {
    const {
      id,
      id_asignatura,
    } = datos
    const {data} = await axios({
      method: 'DELETE',
      url: `/hojavida/${id}`,
    })
    dispatch('fetchObservacionesAsignatura', id_asignatura.id)
  } catch (e) {
    commit('observacionError', e.response.data)
  }
}
