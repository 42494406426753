export default function planApoyo() {
  return 0
}

export function getPlanApoyos(state) {
  return state.planApoyos
}

export function getPlanApoyosAlumnos(state) {
  return state.planApoyosAlumnos
}

export function getPlanApoyoHorarios(state) {
  return state.horarios
}

export function getPlanApoyoHorario(state) {
  return state.horarioSelected
}
