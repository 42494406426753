export function setAsignaturaConfig(state, asignaturaConfig) {
  state.asignaturaConfig = asignaturaConfig
}

// CONFIGS
export function SET_LOADING(state, payload) {
  state.loading = payload
}

export function setResponse(state, payload) {
  state.response.status = payload.status
  state.response.message = payload.message
}

export function asignaturaConfigError(state, payload) {
  state.error = true
  state.errorMessage = payload
  state.asignaturaConfig = []
}

export function asignaturaConfigErrorNull(state) {
  state.error = false
  state.errorMessage = []
}
