import axios from 'axios'

export default async function matriculas() {
  return 0
}

export async function fetchMatricula({ commit }, id_matricula) {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `matricula/${id_matricula}`,
    })
    commit('setMatricula', data)
  } catch (e) {
    commit('MatriculaError', e.response.data)
  }
}

export async function fetchMatriculaNucleo({ commit }, id_matricula) {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `matriculanucleo/${id_matricula}`,
    })
    commit('setMatriculaNucleo', data)
  } catch (e) {
    commit('MatriculaError', e.response.data)
  }
}

export async function fetchMatriculaClinico({ commit }, id_matricula) {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `matriculaclinico/${id_matricula}`,
    })
    commit('setMatriculaClinico', data)
  } catch (e) {
    commit('MatriculaError', e.response.data)
  }
}

export async function fetchMatriculasEstablecimiento({ commit }, id_establecimiento) {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `matriculaestablecimiento/${id_establecimiento}`,
    })
    commit('setMatriculas', data)
  } catch (e) {
    commit('matriculaError', e.response.data)
  }
}

export async function addMatricula({ commit }, matricula) {
  try {
    commit('matriculaErrorNull')
    const { data } = await axios({
      method: 'POST',
      url: 'matricula',
      data: {
        rut: parseInt(matricula.rut),
        dv: matricula.dv,
        nombre: matricula.nombre,
        segundo_nombre: matricula.segundo_nombre,
        primer_apellido: matricula.primer_apellido,
        segundo_apellido: matricula.segundo_apellido,
        nombre_calle: matricula.nombre_calle,
        numero_calle: matricula.numero,
        celular: matricula.celular,
        correo: matricula.correo,
        id_comuna: matricula.id_comuna,
        id_pais: matricula.id_pais,
        fecha_ingreso: matricula.fecha_ingreso,
        tipo_identificacion: matricula.tipo_identificacion,
        genero: matricula.genero,
        fecha_nacimiento: matricula.fecha_nacimiento,
        repitencia: matricula.repitencia,
        prioritario: matricula.prioritario,
        beneficio: matricula.beneficio,
        pie: matricula.pie,
        religion: matricula.religion,
        junaeb: matricula.junaeb,
        autorizacion: matricula.autorizacion,
        aprendizaje: matricula.aprendizaje,
        transporte: matricula.transporte,
        procedencia: matricula.procedencia,
        id_persona_rol_apoderado: matricula.id_persona_rol_apoderado,
        id_persona_rol_apoderado_suplente: matricula.id_persona_rol_apoderado_suplente,
        id_establecimiento: matricula.id_establecimiento,
        id_cursos: matricula.id_cursos,
        id_rol: 10,
      },
    })
    commit('setResponse', data)
  } catch (e) {
    commit('matriculaError', e.response.data)
  }
}

export async function updateMatricula({ commit }, matricula) {
  try {
    commit('matriculaErrorNull')
    const { data } = await axios({
      method: 'PUT',
      url: `matricula/${matricula.id}`,
      data: {
        rut: parseInt(matricula.rut),
        dv: matricula.dv,
        nombre: matricula.nombre,
        primer_apellido: matricula.primer_apellido,
        segundo_apellido: matricula.segundo_apellido,
        nombre_calle: matricula.nombre_calle,
        numero_calle: matricula.numero,
        celular: matricula.celular,
        correo: matricula.correo,
        id_comuna: matricula.id_comuna,
        id_pais: matricula.id_pais,
        fecha_ingreso: matricula.fecha_ingreso,
        tipo_identificacion: matricula.tipo_identificacion,
        genero: matricula.genero,
        repitencia: matricula.repitencia,
        prioritario: matricula.prioritario,
        beneficio: matricula.beneficio,
        pie: matricula.pie,
        religion: matricula.religion,
        junaeb: matricula.junaeb,
        autorizacion: matricula.autorizacion,
        aprendizaje: matricula.aprendizaje,
        transporte: matricula.transporte,
        procedencia: matricula.procedencia,
        id_persona_rol_apoderado: matricula.id_persona_rol_apoderado,
        id_persona_rol_apoderado_suplente: matricula.id_persona_rol_apoderado_suplente,

        id_establecimiento: matricula.id_establecimiento,
        id_cursos: matricula.id_cursos,
        id_rol: 10,
      },
    })
    commit('setResponse', data)
  } catch (e) {
    commit('matriculaError', e.response.data)
  }
}

export async function removeMatriculas({ commit, dispatch }, id) {
  try {
    await axios({
      method: 'DELETE',
      url: `/matriculas/${id}`,
    })
    dispatch('fetchMatricula')
  } catch (e) {
    commit('matriculaError', e.response.data)
  }
}

export async function addMatriculaNucleo({ commit }, matriculaNucleo) {
  try {
    commit('matriculaErrorNull')
    const { data } = await axios({
      method: 'POST',
      url: 'matriculanucleo',
      data: {
        vive: matriculaNucleo.vive,
        renta: matriculaNucleo.renta,
        integrantes: matriculaNucleo.integrantes,
        camas: matriculaNucleo.camas,
        hacinamiento: matriculaNucleo.hacinamiento,
        certificado_nacimiento: matriculaNucleo.certificado_nacimiento,
        certificado_estudio: matriculaNucleo.certificado_estudio,
        certificado_personalidad: matriculaNucleo.certificado_personalidad,
        nivel_educacional_padre: matriculaNucleo.nivel_educacional_padre,
        nivel_educacional_madre: matriculaNucleo.nivel_educacional_madre,
        id_persona_rol_padre: matriculaNucleo.id_persona_rol_padre,
        id_persona_rol_madre: matriculaNucleo.id_persona_rol_madre,
        id_matricula: matriculaNucleo.id_matricula,
      },
    })
    commit('setResponse', data)
  } catch (e) {
    commit('matriculaError', e.response.data)
  }
}

export async function addMatriculaClinico({ commit }, matriculaClinico) {
  try {
    commit('matriculaErrorNull')
    const { data } = await axios({
      method: 'POST',
      url: 'matriculaclinico',
      data: {
        prevision: matriculaClinico.prevision,
        actividad_fisica: matriculaClinico.actividad_fisica,
        documento_actividad_fisica: matriculaClinico.documento_actividad_fisica,
        patologias: matriculaClinico.patologias,
        tratamiento_medico: matriculaClinico.tratamiento_medico,
        horario_medicacion: matriculaClinico.horario_medicacion,
        id_persona_rol_emergencia: matriculaClinico.id_persona_rol_emergencia,
        id_matricula: matriculaClinico.id_matricula,
      },
    })
    commit('setResponse', data)
  } catch (e) {
    commit('matriculaError', e.response.data)
  }
}

export async function retirarMatricula({ commit }, estudiante) {
  try {
    commit('matriculaErrorNull')
    const { data } = await axios({
      method: 'PUT',
      url: `matricularetiro/${estudiante.id_matricula}`,
      data: {
        motivo_retiro: estudiante.motivo_retiro,
        fecha_retiro: estudiante.fecha_retiro,
      },
    })
    commit('setResponse', data)
  } catch (e) {
    commit('matriculaError', e.response.data)
  }
}
