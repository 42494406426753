export default {
  directores: [],
  docentes: [],
  educadorPie: [],
  equipoPie: [],
  equipoApoyoEspecializado: [],
  profesionales: [],
  alumnos: [],
  directivos: [],
  directores: [],
  participantesPie: [],
  personasRol: [],

  selectedPersona: null,
  id_persona: null,
  status: false,
  message: '',
  error: false,
  errorMessage: [],
}
