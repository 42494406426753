export default function comunicaciones() {
  return 0
}

export function getComunicacion(state) {
  return state.comunicacion
}

export function getComunicaciones(state) {
  return state.comunicaciones
}