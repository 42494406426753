import store from '@/store'

export default [
  {
    path: '/comunicaciones-apoderados',
    name: 'comunicaciones-apoderados',
    component: () => import('@/views/Comunicaciones/Comunicaciones.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'login',
        })
      }
      return next()
    },
    meta: {
      pageTitle: 'Comunicaciones',
      breadcrumb: [
        {
          text: 'Comunicaciones',
          active: true,
        },
      ],
    },
  },
  {
    path: '/comunicacion-create',
    name: 'comunicacion-create',
    component: () => import('@/views/Comunicaciones/components/ComunicacionCreate.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'login',
        })
      }
      return next()
    },
    meta: {
      pageTitle: 'Crear Comunicación',
      breadcrumb: [
        {
          text: 'Comunicaciones',
          to: '/comunicaciones-apoderados',
        },
        {
          text: 'Crear Comunicación',
          active: true,
        },
      ],
    },
  },
  {
    path: '/comunicacion-update',
    name: 'comunicacion-update',
    component: () => import('@/views/Comunicaciones/components/ComunicacionUpdate.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'login',
        })
      }
      return next()
    },
    meta: {
      pageTitle: 'Editar Comunicación',
      breadcrumb: [
        {
          text: 'Comunicaciones',
          to: '/comunicaciones-apoderados',
        },
        {
          text: 'Editar Comunicación',
          active: true,
        },
      ],
    },
  },
]