import axios from 'axios'

export default async function personalidades() {
  return 0
}

export async function fetchPersonalidad({ commit }, id_personalidad) {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `personalidad/${id_personalidad}`,
    })
    commit('setPersonalidad', data)
  } catch (e) {
    commit('personalidadError', e.response.data)
  }
}

export async function fetchPersonalidades({ commit }) {
  try {
    const { data } = await axios.get('personalidad')
    commit('setPersonalidades', data)
  } catch (e) {
    commit('personalidadError', e.response.data)
  }
}

export async function fetchPersonalidadesGrado({ commit }, id_curso) {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `personalidadgrado/${id_curso}`,
    })
    commit('setPersonalidadGrado', data)
  } catch (e) {
    commit('personalidadError', e.response.data)
  }
}

export async function addPersonalidad({ commit }, datos) {
  try {
    commit('personalidadErrorNull')
    const {
      descripcion,
      indicadores,
    } = datos

    const { data } = await axios({
      method: 'POST',
      url: 'personalidad',
      data: {
        descripcion,
        indicadores,
      },
    })
    commit('setResponse', data)
  } catch (e) {
    commit('personalidadError', e.response.data)
  }
}

export async function updatePersonalidad({ commit }, personalidad) {
  try {
    commit('personalidadErrorNull')
    const response = await axios({
      method: 'PUT',
      url: `personalidad/${personalidad.id}`,
      data: {
        descripcion: personalidad.descripcion,
        indicadores: personalidad.indicadores,
      },
    })
  } catch (e) {
    commit('personalidadError', e.response.data)
  }
}

export async function removePersonalidad({ commit, dispatch }, id) {
  try {
    await axios({
      method: 'DELETE',
      url: `/personalidad/${id}`,
    })
    // actualizamos lista de personalidades
    dispatch('fetchPersonalidades')
  } catch (e) {
    commit('personalidadError', e.response.data)
  }
}
