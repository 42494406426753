import axios from 'axios'

export default async function horarios() {
  return 0
}

export async function fetchHorario({ commit }, id_horario) {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `horarioid/${id_horario}`,
    })
    commit('setHorario', data)
  } catch (e) {
    commit('setHorarios', e.response.data)
  }
}

export async function fetchHorarioUsuarioCurso({ commit }, id_curso) {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `horariousuariocurso/${id_curso}`,
    })
    commit('setHorarios', data)
  } catch (e) {
    commit('setHorarios', e.response.data)
  }
}

export async function fetchHorarioUsuarioCursoDistinct({ commit }, id_curso) {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `horariousuariocursodistinct/${id_curso}`,
    })
    commit('setHorarios', data)
  } catch (e) {
    commit('setHorarios', e.response.data)
  }
}
