import store from '@/store'

export default [
  {
    path: '/reuniones-apoderados',
    name: 'reuniones-apoderados',
    component: () => import('@/views/ReunionesApoderado/Reuniones.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'login',
        })
      }
      return next()
    },
    meta: {
      pageTitle: 'Reuniones',
      breadcrumb: [
        {
          text: 'Reuniones',
          active: true,
        },
      ],
    },
  },
  {
    path: '/reunion-create',
    name: 'reunion-create',
    component: () => import('@/views/ReunionesApoderado/components/ReunionApoderadoCreate.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'login',
        })
      }
      return next()
    },
    meta: {
      pageTitle: 'Crear Reunión',
      breadcrumb: [
        {
          text: 'Reuniones',
          to: '/reuniones-apoderados',
        },
        {
          text: 'Crear Reunión',
          active: true,
        },
      ],
    },
  },
  {
    path: '/reunion-update',
    name: 'reunion-update',
    component: () => import('@/views/ReunionesApoderado/components/ReunionApoderadoUpdate.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'login',
        })
      }
      return next()
    },
    meta: {
      pageTitle: 'Editar Reunión',
      breadcrumb: [
        {
          text: 'Reuniones',
          to: '/reuniones-apoderados',
        },
        {
          text: 'Editar Reunión',
          active: true,
        },
      ],
    },
  },
]