export default {
  personalidad: {},
  personaldiades: [],
  personalidadGrado: [],
  selectedCurso: null,
  status: false,
  message: '',
  error: false,
  errorMessage: [],
}
