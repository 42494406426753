import axios from 'axios'

export default async function notas() {
  return 0
}

export async function fetchNotas({ commit }, id_asignatura) {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `notaasignatura/${id_asignatura}`,
    })
    commit('setNotas', data)
  } catch (e) {
    commit('notaError', e.response.data)
  }
}

export async function fetchNotasSumativa({ commit }, id_asignatura) {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `notaasignaturasumativa/${id_asignatura}`,
    })
    commit('setNotas', data)
  } catch (e) {
    commit('notaError', e.response.data)
  }
}

export async function fetchNotasUser({ commit }, idUsuario) {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `curso/${idUsuario}`,
    })
    commit('setNotas', data)
  } catch (e) {
    commit('notaError', e.response.data)
  }
}

export async function addNota({ commit }, nota) {
  try {
    commit('notaErrorNull')
    const { data } = await axios({
      method: 'PUT',
      url: `nota/${nota.id_nota}`,
      data: {
        id_nota: nota.id_nota,
        id_persona_rol_alumno: nota.id_persona_rol_alumno,
        id_evaluacion: nota.id_evaluacion,
        nota: nota.nota,
      },
    })
    commit('setResponse', data)
  } catch (e) {
    commit('notaError', e.response.data)
  }
}

export async function updateNota({ commit }, nota) {
  try {
    commit('notaErrorNull')
    const { data } = await axios({
      method: 'PUT',
      url: `nota/${nota.id_nota}`,
      data: {
        id_nota: nota.id_nota,
        id_persona_rol_alumno: nota.id_persona_rol_alumno,
        id_evaluacion: nota.id_evaluacion,
        nota: nota.nota,
      },
    })
    commit('setResponse', data)
  } catch (e) {
    commit('notaError', e.response.data)
  }
}

export async function updateNotaSumativa({ commit }, nota) {
  try {
    console.log('nota :', nota)
    commit('notaErrorNull')
    const { data } = await axios({
      method: 'PUT',
      url: `notasumativa/${nota.id_nota}`,
      data: {
        id_nota: nota.id_nota,
        id_persona_rol_alumno: nota.id_persona_rol_alumno,
        id_evaluacion: nota.id_evaluacion,
        nota: nota.nota,
      },
    })
    commit('setResponse', data)
  } catch (e) {
    commit('notaError', e.response.data)
  }
}

// export async function updateNotaPeriodo({ commit }, nota) {
//   try {
//     commit('notaErrorNull')
//     const fecha = new Date()
//     const dia = fecha.getDate()
//     const mes = (fecha.getMonth() + 1)
//     const year = fecha.getFullYear()
//     const fechaInicioPeriodoActivo = `${year}-${mes}-${dia}`
//     await axios({
//       method: 'PUT',
//       url: `/notas/periodoActivo/${nota.id}`,
//       data: {
//         idPeriodoActivo: nota.idPeriodoActivo,
//         fechaInicioPeriodoActivo,
//       },
//     })
//   } catch (e) {
//     commit('notaError', e.response.data)
//   }
// }

// export async function updateNotaEstado({ commit, dispatch }, nota) {
//   let est = ''
//   try {
//     commit('notaErrorNull')
//     if (nota.estado === 'Inactivo') {
//       est = 'Activo'
//     } else if (nota.estado === 'Activo') {
//       est = 'Inactivo'
//     }
//     await axios({
//       method: 'PUT',
//       url: `/notas/${nota.id}`,
//       data: {
//         nombre: nota.nombre,
//         estado: est,
//       },
//     })
//     // actualizamos lista de notas
//     dispatch('fetchNotas')
//     dispatch('fetchNotasActivas')
//   } catch (e) {
//     commit('notaError', e.response.data)
//   }
// }

export async function removeNotas({ commit, dispatch }, id) {
  try {
    await axios({
      method: 'DELETE',
      url: `/notas/${id}`,
    })
    // actualizamos lista de notas
    dispatch('fetchNotas')
  } catch (e) {
    commit('notaError', e.response.data)
  }
}
