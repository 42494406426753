export default function usuario() {
  return 0
}

export function getUsuarios(state) {
  return state.usuarios
}

// export function getEstablecimientosActivos(state) {
//   return state.establecimientosActivos
// }
