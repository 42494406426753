

export function setInformesPersonalidad(state, informesPersonalidad) {
  state.informesPersonalidad = informesPersonalidad
}

export function setInformePersonalidadSelected(state, informePersonalidad) {
  state.selectedInformePersonalidad = informePersonalidad
}

export function setParcial(state, parcial) {
  state.parcial = parcial
}

export function setResponse(state, payload) {
  state.status = payload.status
  state.message = payload.message
}

export function personalidadError(state, payload) {
  state.error = true
  state.errorMessage = payload
  state.personalidades = []
}

export function personalidadErrorNull(state) {
  state.error = false
  state.errorMessage = []
}
