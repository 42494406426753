export default function horario() {
  return 0
}

export function getHorarios(state) {
  return state.horarios
}

export function getHorario(state) {
  return state.horario
}
