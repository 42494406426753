import store from '@/store'

export default [
  {
    path: '/carga-horaria',
    name: 'carga-horaria',
    component: () => import('@/views/ConfiguracionInicial/CargaHoraria.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'login',
        })
      }
      return next()
    },
    meta: {
      pageTitle: 'Ingreso de la carga horaria',
      // breadcrumb: [
      //   {
      //     text: 'Ingreso de la carga horaria',
      //     active: true,
      //   },
      // ],
    },
  },
]