import axios from 'axios'

export default async function ceds() {
  return 0
}

export async function fetchPaises({ commit, dispatch }) {
  try {
    const { data } = await axios.get('paises')

    commit('setPaises', data)
  } catch (e) {
    commit('cedsError', e.response.data)
  }
}

export async function fetchComunas({ commit, dispatch }) {
  try {
    const { data } = await axios.get('comunas')

    commit('setComunas', data)
  } catch (e) {
    commit('cedsError', e.response.data)
  }
}
