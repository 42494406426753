export default function concepto() {
  return 0
}

export function getConcepto(state) {
  return state.concepto
}

export function getConceptos(state) {
  return state.conceptos
}

export function getConceptosPersonaRol(state) {
  return state.conceptosPersonaRol
}

export function getConceptosActivos(state) {
  return state.conceptosActivos
}
