export default {
  contenidos: [],
  contenidosMensual: [],
  tipoContenido: [],
  id_fecha_curso: null,
  id_contenido: null,
  contenidosActivos: [],
  selectedLibro: null,
  error: false,
  errorMessage: [],
  status: false,
  message: '',
}
