export function setConceptos(state, conceptos) {
  state.conceptos = conceptos
}

export function setConceptosPersonaRol(state, conceptosPersonaRol) {
  state.conceptosPersonaRol = conceptosPersonaRol
}

export function setConceptosActivos(state, conceptos) {
  state.conceptosActivos = conceptos
}

export function setConceptoSelected(state, concepto) {
  state.selectedConcepto = concepto
}

export function setConcepto(state, concepto) {
  state.concepto = concepto
}

export function setResponse(state, payload) {
  state.status = payload.status
  state.message = payload.message
}

export function updateConceptoEstado(state, payload) {
  const concepto = state.conceptos.find(u => u.id === payload.id)
  if (concepto) {
    state.done = !concepto.done
  }
}

export function conceptoError(state, payload) {
  state.error = true
  state.errorMessage = payload
  state.conceptos = []
}

export function conceptoErrorNull(state) {
  state.error = false
  state.errorMessage = []
}
