export default function calendario() {
  return 0
}

export function getCalendarios(state) {
  return state.calendarios
}

export function getCalendariosActivos(state) {
  return state.calendariosActivos
}
