export default {
  asistencias: [],
  asistenciasMensual: [],
  id_fecha_curso: null,
  tipoAsistencia: [],
  asistenciasActivos: [],
  selectedLibro: null,
  error: false,
  errorMessage: [],
  status: false,
  message: '',
}
