export default function salidas() {
  return 0
}

export function getSalida(state) {
  return state.salida
}

export function getSalidas(state) {
  return state.salidas
}
