export default function estrategia() {
  return 0
}

export function getEstrategia(state) {
  return state.estrategia
}

export function getEstrategiaDetalle(state) {
  return state.estrategiaDetalle
}
