export default function nota() {
  return 0
}

export function getObservaciones(state) {
  return state.observaciones
}

export function getObservacionesAsignatura(state) {
  return state.observacionesAsignatura
}

export function getObservacionesActivos(state) {
  return state.observacionesActivos
}

export function getObservacionSelected(state) {
  return state.selectedObservacion
}
