import axios from 'axios'

export default async function conceptos() {
  return 0
}

export async function fetchConcepto({ commit }, id_concepto) {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `concepto/${id_concepto}`,
    })
    commit('setConcepto', data)
  } catch (e) {
    commit('conceptoError', e.response.data)
  }
}

export async function fetchConceptos({ commit }) {
  try {
    const { data } = await axios.get('concepto')
    commit('setConceptos', data)
  } catch (e) {
    commit('conceptoError', e.response.data)
  }
}

export async function addConcepto({ commit }, datos) {
  try {
    commit('conceptoErrorNull')

    const {
      descripcion,
      indicadores,
    } = datos

    const { data } = await axios({
      method: 'POST',
      url: 'concepto',
      data: {
        descripcion,
        indicadores,
      },
    })

    commit('setResponse', data)
  } catch (e) {
    commit('conceptoError', e.response.data)
  }
}

export async function updateConcepto({ commit }, concepto) {
  try {
    commit('conceptoErrorNull')
    const response = await axios({
      method: 'PUT',
      url: `concepto/${concepto.id}`,
      data: {
        descripcion: concepto.descripcion,
        indicadores: concepto.indicadores,
      },
    })
  } catch (e) {
    commit('conceptoError', e.response.data)
  }
}

export async function removeConcepto({ commit, dispatch }, id) {
  try {
    await axios({
      method: 'DELETE',
      url: `/concepto/${id}`,
    })
    // actualizamos lista de conceptos
    dispatch('fetchConceptos')
  } catch (e) {
    commit('conceptoError', e.response.data)
  }
}
