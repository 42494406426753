export function setSalida(state, salida) {
  state.salida = salida
}

export function setSalidas(state, salidas) {
  state.salidas = salidas
}

export function setResponse(state, payload) {
  state.status = payload.status
  state.message = payload.message
}

export function setSalidaSelected(state, selectedSalida) {
  state.selectedSalida = selectedSalida
}
