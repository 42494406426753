export default {
  establecimientos: [],
  aproximaciones: {},
  establecimientosActivos: [],
  selectedEstablecimiento: null,
  status: false,
  message: '',
  error: false,
  errorMessage: [],
}
