export function setPaises(state, paises) {
  state.paises = paises
}

export function setComunas(state, comunas) {
  state.comunas = comunas
}

// Errors
export function cedsError(state, payload) {
  state.error = true
  state.errorMessage = payload
  state.cedss = []
}

export function cedsErrorNull(state) {
  state.error = false
  state.errorMessage = []
}
