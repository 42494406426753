export function setReemplazos(state, reemplazos) {
  state.reemplazos = reemplazos
}

export function setResponse(state, payload) {
  state.status = payload.status
  state.message = payload.message
}

export function reemplazoError(state, payload) {
  state.error = true
  state.errorMessage = payload
  state.reemplazos = []
}

export function reemplazoErrorNull(state) {
  state.error = false
  state.errorMessage = []
}
