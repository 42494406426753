import axios from 'axios'

export default async function firmas() {
  return 0
}



export async function fetchFirmas({ commit, dispatch }) {
  try {
    const { data } = await axios.get('firma')
    commit('setFirma', data)
  } catch (e) {
    commit('firmaError', e.response.data)
  }
}

export async function addFirmaAsistencia({ commit }, firma) {
  try {
    commit('firmaErrorNull')
    const { data } = await axios({
      method: 'POST',
      url: 'firma',
      data: {
        id_fecha_curso: firma.id_fecha_curso,
        id_horario: firma.id_bloque,
        tipo: 1,
        token: firma.token,
      },
    })
    commit('setResponse', data)
  } catch (e) {
    commit('firmaError', e.response.data)
  }
}

export async function addFirmaContenido({ commit }, firma) {
  try {
    commit('firmaErrorNull')
    const { data } = await axios({
      method: 'POST',
      url: 'firma',
      data: {
        id_fecha_curso: firma.id_fecha_curso,
        id_horario: firma.id_bloque,
        token: firma.token,
        tipo: 2,
      },
    })
    commit('setResponse', data)
  } catch (e) {
    commit('firmaError', e.response.data)
  }
}

// export async function updateFirma({ commit }, firma) {
//   try {
//     commit('firmaErrorNull')
//     const { data }  = await axios({
//       method: 'PUT',
//       url: `firma/${firma.id_firma}`,
//       data: {
//         id_asistencia: firma.id_asistencia,
//         token: firma.clave,
//       },
//     })
//   } catch (e) {
//     commit('firmaError', e.response.data)
//   }
// }

// export async function removeFirmas({ commit, dispatch }, id) {
//   try {
//     await axios({
//       method: 'DELETE',
//       url: `/firmas/${id}`,
//     })
//     // actualizamos lista de firmas
//     dispatch('fetchFirmas')
//   } catch (e) {
//     commit('firmaError', e.response.data)
//   }
// }
