export default function asignatura() {
  return 0
}

export function getAsignaturas(state) {
  return state.asignaturas
}

export function getAsignaturasCurso(state) {
  return state.asignaturasCurso
}

export function getAsignaturasActivos(state) {
  return state.asignaturasActivos
}

export function getTiposAsignatura(state) {
  return state.optionsTiposAsignatura
}

// CONFIGS
export function getLoading(state) {
  return state.loading
}
