export default function persona() {
  return 0
}

export function getPersona(state) {
  return state.selectedPersona
}

export function getPersonasRol(state) {
  return state.personasRol
}

export function getAlumnos(state) {
  return state.alumnos
}

export function getDirectivos(state) {
  return state.directivos
}

export function getDirectores(state) {
  return state.directores
}

export function getParticipantesPie(state) {
  return state.participantesPie
}

export function getEquipoPie(state) {
  return state.equipoPie
}

export function getEquipoApoyoEspecializado(state) {
  return state.equipoApoyoEspecializado
}

// export function getEstablecimientosActivos(state) {
//   return state.establecimientosActivos
// }
