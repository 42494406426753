export default function matriculas() {
  return 0
}

export function getMatricula(state) {
  return state.matricula
}

export function getMatriculas(state) {
  return state.matriculas
}

export function getMatriculaNucleo(state) {
  return state.matriculaNucleo
}

export function getMatriculaClinico(state) {
  return state.matriculaClinico
}
