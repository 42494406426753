export function setMatricula(state, matricula) {
  state.selectedMatricula = matricula
}

export function setMatriculas(state, matriculas) {
  state.matriculas = matriculas
}

export function setMatriculaNucleo(state, matriculaNucleo) {
  state.matriculaNucleo = matriculaNucleo
}

export function setMatriculaClinico(state, matriculaClinico) {
  state.matriculaClinico = matriculaClinico
}

export function setResponse(state, payload) {
  state.status = payload.status
  state.message = payload.message
  state.id_matricula = payload.id
}


// Errors
export function matriculaError(state, payload) {
  state.error = true
  state.errorMessage = payload
  state.matriculas = []
}

export function matriculaErrorNull(state) {
  state.error = false
  state.errorMessage = []
}
