export default function ceds() {
  return 0
}

export function getPaises(state) {
  return state.paises
}

export function getComunas(state) {
  return state.comunas
}
