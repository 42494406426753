export default {
  observaciones: [],
  observacionesAsignatura: [],
  observacionesActivos: [],
  selectedLibro: null,

  message: null,
  status: null,
  error: false,
  errorMessage: [],
}
