import axios from 'axios'

export default async function contenidos() {
  return 0
}

export async function fetchContenidosMensual({ commit, dispatch }, datos) {
  try {
    const {
      id_curso,
      mes
    } = datos
    const { data } = await axios({
      method: 'GET',
      url: `controlcontenido/${id_curso}/${mes}`,
    })
    commit('setContenidosMensual', data)
  } catch (e) {
    commit('contenidoError', e.response.data)
  }
}

export async function fetchContenidos({ commit, dispatch }, datos) {
  try {
    const {
      id_curso,
      fecha
    } = datos
    const { data } = await axios({
      method: 'GET',
      url: `bloquecontenido/${id_curso}/${fecha}`,
    })
    commit('setContenidos', data)
  } catch (e) {
    commit('contenidoError', e.response.data)
  }
}

export async function addContenido({ commit }, contenido) {
  try {
    commit('contenidoErrorNull')
    const {data} = await axios({
      method: 'POST',
      url: 'controlcontenido',
      data: {
        fecha: contenido.fecha,
        id_curso: contenido.id_curso,
        contenido: contenido.contenido,
        id_horario: contenido.id_horario,
      },
    })
    commit('setDataResponse', data)
    commit('setResponse', data)
  } catch (e) {
    commit('contenidoError', e.response.data)
  }
}

export async function updateContenido({ commit }, contenido) {
  try {
    commit('contenidoErrorNull')
    const { data } = await axios({
      method: 'PUT',
      url: `controlcontenido/${contenido.id}`,
      data: {
        contenido: contenido.contenido,
      },
    })
    commit('setResponse', data)
  } catch (e) {
    commit('contenidoError', e.response.data)
  }
}

export async function removeContenidos({ commit, dispatch }, id) {
  try {
    await axios({
      method: 'DELETE',
      url: `/contenidos/${id}`,
    })
    // actualizamos lista de contenidos
    dispatch('fetchContenidos')
  } catch (e) {
    commit('contenidoError', e.response.data)
  }
}
