export default {
  matricula: [],
  matriculas: [],
  matriculaNucleo: [],
  matriculaClinico: [],
  selectedMatricula: null,
  id_matricula: null,

  status: false,
  message: '',

  error: false,
  errorMessage: [],
}
