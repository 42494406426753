export function setComunicacion(state, comunicacion) {
  state.comunicacion = comunicacion
}

export function setComunicaciones(state, comunicaciones) {
  state.comunicaciones = comunicaciones
}

export function setResponse(state, payload) {
  state.status = payload.status
  state.message = payload.message
}

export function setComunicacionSelected(state, selectedComunicacion) {
  state.selectedComunicacion = selectedComunicacion
}
