export function setReunion(state, reunion) {
  state.reunion = reunion
}

export function setReuniones(state, reuniones) {
  state.reuniones = reuniones
}

export function setResponse(state, payload) {
  state.status = payload.status
  state.message = payload.message
}

export function setReunionSelected(state, selectedReunion) {
  state.selectedReunion = selectedReunion
}
