import axios from 'axios'
import { LCircle } from 'vue2-leaflet'

export default async function reemplazo() {
  return 0
}

export async function fetchReemplazos({ commit }, id_establecimiento) {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `reemplazo/${id_establecimiento}`,
    })
    commit('setReemplazos', data)
  } catch (e) {
    commit('reemplazoError', e.response.data)
  }
}

export async function addReemplazo({ commit }, reemplazo) {
  try {
    commit('reemplazoErrorNull')
    const { data } = await axios({
      method: 'POST',
      url: 'reemplazo',
      data: {
        fecha_inicio: reemplazo.fecha_inicio,
        fecha_termino: reemplazo.fecha_termino,
        id_persona_rol_docente: reemplazo.id_persona_rol_docente,
        id_persona_rol_reemplazo: reemplazo.id_persona_rol_reemplazo,
      },
    })
    commit('setResponse', data)
  } catch (e) {
    commit('reemplazoError', e.response.data)
  }
}

export async function updateUsuario({ commit }, reemplazo) {
  try {
    commit('reemplazoErrorNull')
    const response = await axios({
      method: 'PUT',
      url: `reemplazo/${reemplazo.id}`,
      data: {
        rbd: reemplazo.rbd,
        nombre: reemplazo.nombre,
        abreviatura: reemplazo.abreviatura,
        nombre_calle: reemplazo.nombre_calle,
        numero_calle: reemplazo.numero_calle,
        celular: reemplazo.celular,
        correo: reemplazo.correo,
      },
    })
  } catch (e) {
    commit('reemplazoError', e.response.data)
  }
}

export async function updateUsuarioEstado({ commit, dispatch }, reemplazo) {
  let est = ''
  try {
    commit('reemplazoErrorNull')
    if (reemplazo.estado === 'Inactivo') {
      est = 'Activo'
    } else if (reemplazo.estado === 'Activo') {
      est = 'Inactivo'
    }
    await axios({
      method: 'PUT',
      url: `/reemplazo/${reemplazo.id}`,
      data: {
        nombre: reemplazo.nombre,
        estado: est,
      },
    })
    // actualizamos lista de reemplazo
    dispatch('fetchReemplazo')
    dispatch('fetchReemplazoActivas')
  } catch (e) {
    commit('reemplazoError', e.response.data)
  }
}

export async function removeReemplazo({ commit, dispatch }, id) {
  try {
    await axios({
      method: 'DELETE',
      url: `/reemplazo/${id}`,
    })
    // actualizamos lista de reemplazo
    dispatch('fetchReemplazo')
  } catch (e) {
    commit('reemplazoError', e.response.data)
  }
}
