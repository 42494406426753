export default function informe() {
  return 0
}

export function getObservaciones(state) {
  return state.observaciones
}

export function getObservacion(state) {
  return state.observacion
}