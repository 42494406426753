import axios from 'axios'

export default async function personalidades() {
  return 0
}

export async function fetchPersonalidad({ commit }, id_personalidad) {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `personalidad/${id_personalidad}`,
    })
    commit('setParcial', data)
  } catch (e) {
    commit('personalidadError', e.response.data)
  }
}

export async function fetchInformesPersonalidad({ commit }, datos) {
  try {
    const {
      id_curso,
      id_periodo,
    } = datos
    const { data } = await axios({
      method: 'GET',
      url: `personalidadcurso/${id_curso}/${id_periodo}`,
    })
    commit('setInformesPersonalidad', data)
  } catch (e) {
    commit('personalidadError', e.response.data)
  }
}

export async function addInformePersonalidad({ commit }, datos) {
  try {
    commit('personalidadErrorNull')
    const {
      indicadores,
      id_periodo,
      id_persona_rol_estudiante,
      observacion,
    } = datos

    const { data } = await axios({
      method: 'POST',
      url: 'personalidadalumno',
      data: {
        observacion,
        indicadores,
        id_periodo,
        id_persona_rol_estudiante,
      },
    })
    commit('setResponse', data)
  } catch (e) {
    commit('personalidadError', e.response.data)
  }
}

export async function updateInformePersonalidad({ commit }, datos) {
  try {
    commit('personalidadErrorNull')
    const {
      observacion,
      indicadores,
      id_periodo,
      id_persona_rol_estudiante,
    } = datos

    const { data } = await axios({
      method: 'POST',
      url: `personalidadalumno`,
      data: {
        observacion,
        indicadores,
        id_periodo,
        id_persona_rol_estudiante,
      },
    })
    commit('setResponse', data)
  } catch (e) {
    commit('personalidadError', e.response.data)
  }
}
