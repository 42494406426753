export default {
  planApoyos: [],
  planApoyosAlumnos: [],
  horarios: [],
  horariosSelected: null,
  dataResponse: [],
  status: false,
  statusHorario: false,
  error: false,
  errorMessage: [],
}
