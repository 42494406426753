export function informeError(state, payload) {
  state.error = true
  state.errorMessage = payload
  state.informes = []
}

export function informeErrorNull(state) {
  state.error = false
  state.errorMessage = []
}

export function setObservaciones(state, observaciones) {
  state.observaciones = observaciones
}

export function setObservacion(state, observacion) {
  state.observacion = observacion
}

export function setParcialSelected(state, parcial) {
  state.selectedParcial = parcial
}

export function setParcial(state, parcial) {
  state.parcial = parcial
}

export function setResponse(state, payload) {
  state.status = payload.status
  state.message = payload.message
}

export function parcialError(state, payload) {
  state.error = true
  state.errorMessage = payload
  state.parciales = []
}

export function parcialErrorNull(state) {
  state.error = false
  state.errorMessage = []
}

