import store from '@/store'

export default [
  {
    path: '/salidas',
    name: 'salidas',
    component: () => import('@/views/Salidas/Salidas.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'login',
        })
      }
      return next()
    },
    meta: {
      pageTitle: 'Salidas',
      breadcrumb: [
        {
          text: 'Salidas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/salida-create',
    name: 'salida-create',
    component: () => import('@/views/Salidas/components/SalidaCreate.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'login',
        })
      }
      return next()
    },
    meta: {
      pageTitle: 'Crear Salida',
      breadcrumb: [
        {
          text: 'Salidas',
          to: '/salidas',
        },
        {
          text: 'Crear Salida',
          active: true,
        },
      ],
    },
  },
  {
    path: '/salida-update',
    name: 'salida-update',
    component: () => import('@/views/Salidas/components/SalidaUpdate.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'login',
        })
      }
      return next()
    },
    meta: {
      pageTitle: 'Editar Salida',
      breadcrumb: [
        {
          text: 'Salidas',
          to: '/salidas',
        },
        {
          text: 'Editar Salida',
          active: true,
        },
      ],
    },
  },
]