import axios from 'axios'

export default async function asignaturasConfig() {
  return 0
}

export async function fetchAsignaturaConfig({ commit }, id_asignatura) {
  commit('SET_LOADING', true)
  try {
    const { data } = await axios.get(`configuracion/asignatura/${id_asignatura}`)
    commit('setAsignaturaConfig', data)
    commit('SET_LOADING', false)
  } catch (e) {
    commit('asignaturaConfigError', e.response.data)
  }
}

export async function updateAsignaturaConfig({ commit }, asignaturaConfig) {
  try {
    commit('asignaturaConfigErrorNull')
    const { data } = await axios({
      method: 'PUT',
      url: `configuracion/asignatura/${asignaturaConfig.id_asignatura}`,
      data: {
        forma: asignaturaConfig.forma,
        id_asignatura: asignaturaConfig.id_asignatura,
        id_asignatura_destino: asignaturaConfig.id_asignatura_destino,
        id_estado: asignaturaConfig.id_estado,
      },
    })
    commit('setResponse', data)
  } catch (e) {
    commit('asignaturaConfigError', e.response.data)
  }
}

export async function removeAsignaturaConfig({ commit, dispatch }, datos) {
  try {
    await axios({
      method: 'DELETE',
      url: `/asignatura/${datos.id_asignatura}`,
    })
    // actualizamos lista de asignaturas
    dispatch('fetchAsignaturasCurso', datos.id_curso)
  } catch (e) {
    commit('asignaturaError', e.response.data)
  }
}
