import axios from 'axios'

export default async function configuraciones() {
  return 0
}

export async function fetchConfiguraciones({ commit }, id_establecimiento) {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `configuracion/${id_establecimiento}`,
    })
    commit('setConfiguraciones', data)
  } catch (e) {
    commit('configuracionError', e.response.data)
  }
}
