export default function asignaturaConfig() {
  return 0
}

export function getAsignaturaConfig(state) {
  return state.asignaturaConfig
}

// CONFIGS
export function getLoading(state) {
  return state.loading
}
