import store from '@/store'

export default [
  {
    path: '/cursos/asignaturas',
    name: 'asignaturas',
    component: () => import('@/views/Mantenedores/Cursos/Asignaturas/Asignaturas.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'login',
        })
      }
      return next()
    },
    meta: {
      pageTitle: 'Asignaturas',
      breadcrumb: [
        {
          text: 'Cursos',
          to: '/cursos',
        },
        {
          text: 'Asignaturas',
          active: true,
        },
      ],
    },
  },
]