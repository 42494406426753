export function setEvaluaciones(state, evaluaciones) {
  state.evaluaciones = evaluaciones
}

export function setEvaluacionesSumativas(state, evaluaciones) {
  state.evaluacionesSumativas = evaluaciones
}

export function setEvaluacionesParaSumativa(state, evaluaciones) {
  state.evaluacionesParaSuamtiva = evaluaciones
}

export function setEvaluacionesActivos(state, evaluaciones) {
  state.evaluacionesActivos = evaluaciones
}

export function setEvaluacion(state, evaluacion) {
  state.selectedEvaluacion = evaluacion
}


export function updateEvaluacionEstado(state, payload) {
  const evaluacion = state.evaluaciones.find(u => u.id === payload.id)
  if (evaluacion) {
    state.done = !evaluacion.done
  }
}

export function setResponse(state, payload) {
  state.status = payload.status
  state.message = payload.message
  if (typeof payload.id_evaluacion !== 'undefined') {
    state.id_evaluacion = payload.id_evaluacion
  }
}

export function evaluacionError(state, payload) {
  state.error = true
  state.errorMessage = payload
  state.evaluaciones = []
}

export function evaluacionErrorNull(state) {
  state.error = false
  state.errorMessage = []
}
