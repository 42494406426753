import store from '@/store'

export default [
  {
    path: '/libros',
    name: 'libros',
    component: () => import('@/views/MisLibros/Libros.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'login',
        })
      }
      return next()
    },
    meta: {
      pageTitle: 'Libros',
      breadcrumb: [
        {
          text: 'Libros',
          active: true,
        },
      ],
    },
  },

  // Finalmente será como el update
  {
    path: '/mis-libros/:id/libro',
    name: 'libro',
    component: () => import('@/views/MisLibros/VerLibros.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'login',
        })
      }
      return next()
    },
    meta: {
      pageTitle: '',
      configs: false,
      breadcrumb: [
        {
          text: 'Libros',
          to: '/libros',
        },
        {
          text: 'Libro',
          active: true,
          configs: true,
          optionsConfigs: [
            {
              to: 'config-curso',
              txtBtn: 'Configurar Curso',
              icon: 'ToolIcon',
            },
            {
              to: 'config-asignaturas',
              txtBtn: 'Configurar Asignaturas',
              icon: 'ToolIcon',
            },
            {
              to: 'config-promedios',
              txtBtn: 'Configurar Promedios',
              icon: 'ToolIcon',
            },
          ]
        },
      ],
    },
  },
]