import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
// import { canNavigate } from '@/libs/acl/routeProtection'
// import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

// Acceso
import login from './login'
import error from './error404'

// Configuraciones iniciales
import cargaHoraria from './configuracionesIniciales/cargaHoraria'

// Menu Desplegable
import miPerfil from './menuDesplegable/miPerfil'

// Menu
import inicio from './inicio/inicio'
import misLibros from './libro/misLibros'
import libros from './libro/libros'
import miHorario from './libro/miHorario'
import matriculas from './matriculas/matriculas'
import informes from './informes'
import reuniones from './reuniones'
import salidas from './salidas'
import comunicaciones from './comunicaciones'

// Mantenedor
import alumnos from './mantenedores/alumnos'
import establecimientos from './mantenedores/establecimientos'
import cursos from './mantenedores/cursos'
import asignaturas from './mantenedores/asignaturas'
// Plantillas
import personalidad from './mantenedores/plantillas/personalidad'

import configEstablecimientos from './mantenedores/configEstablecimientos'
import calendarios from './mantenedores/calendarios'
import periodos from './mantenedores/periodos'
import horarios from './mantenedores/horarios'
import reemplazos from './mantenedores/reemplazos'
import usuarios from './mantenedores/usuarios'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    // * Acceso
    ...login,
    ...error,

    // * CONFIGURACIONES INICIALES
    // - docente
    ...cargaHoraria,

    // * MENU DESPLEGABLE

    ...miPerfil,

    // * Menu
    ...inicio,

    // - docente
    ...miHorario,
    ...misLibros,

    // Super Admin - director y jefe de utp
    ...libros,
    ...salidas,
    ...comunicaciones,

    // - secretaria
    ...matriculas,

    // informes
    ...informes,
    ...reuniones,

    // * Mantenedor
    // - super admin
    ...periodos,
    ...establecimientos,
    ...calendarios,

    // director y jefe utp
    ...configEstablecimientos,
    ...cursos,
    ...asignaturas,

    // plantillas
    ...personalidad,

    ...alumnos,
    ...horarios,
    ...reemplazos,
    ...usuarios,
    {
      path: '*',
      redirect: 'inicio',
    },
  ],
})

// router.beforeEnter ((to, from, next) => {
//   if (!store.getters['auth/authenticated']) {
//     return next({
//       name: 'login',
//     })
//   }
//   return next()
// })
// router.beforeEach((to, _, next) => {
//   const isLoggedIn = isUserLoggedIn()

//   if (!canNavigate(to)) {
//     // Redirect to login if not logged in
//     if (!isLoggedIn) return next({ name: 'auth-login' })

//     // If logged in => not authorized
//     return next({ name: 'misc-not-authorized' })
//   }

//   // Redirect if logged in
//   if (to.meta.redirectIfLoggedIn && isLoggedIn) {
//     const userData = getUserData()
//     next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
//   }

//   return next()
// })
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
// router.afterEach(() => {
//   localStorage.setItem('userData', JSON.stringify(store.getters['auth/user']))
//   // Remove initial loading
//   const appLoading = document.getElementById('loading-bg')
//   if (appLoading) {
//     appLoading.style.display = 'none'
//   }
// })

router.afterEach(() => {
  localStorage.setItem('userData', JSON.stringify(store.getters['auth/user']))
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})


export default router
