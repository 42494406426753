export default function reuniones() {
  return 0
}

export function getReunion(state) {
  return state.reunion
}

export function getReuniones(state) {
  return state.reuniones
}