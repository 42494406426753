export default {
  curso: {},
  cursos: [],
  cursosPersonaRol: [],
  cursosActivos: [],
  selectedCurso: null,
  status: false,
  message: '',
  error: false,
  errorMessage: [],
}
