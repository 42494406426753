import axios from 'axios'

export default async function salidas() {
  return 0
}

export async function fetchSalida({ commit }, id_salida) {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `parcial/${id_salida}`,
    })
    commit('setSalida', data)
  } catch (e) {
    commit('parcialError', e.response.data)
  }
}

export async function fetchSalidas({ commit }, id_establecimiento) {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `retiroestablecimiento/${id_establecimiento}`,
    })
    commit('setSalidas', data)
  } catch (e) {
    console.log('error :', e)
    // commit('salidaError', e.response.data)
  }
}

export async function addSalida({ commit }, datos) {
  try {
    // commit('salidaErrorNull')
    const {
      id_curso,
      fecha,
      hora_salida,
      hora_regreso,
      motivo,
      id_persona_rol_estudiante,
      id_persona_rol_retira,
    } = datos
    const { data } = await axios({
      method: 'POST',
      url: 'retiro',
      data: {
        id_curso,
        fecha,
        hora_salida,
        hora_regreso,
        motivo,
        id_persona_rol_estudiante,
        id_persona_rol_retira,
      },
    })
    commit('setResponse', data)
  } catch (e) {
    console.log('error :', e)
    // commit('parcialError', e.response.data)
  }
}

export async function updateSalida({ commit }, datos) {
  try {
    // commit('parcialErrorNull')
    const {
      id,
      id_curso,
      fecha,
      hora_salida,
      hora_regreso,
      motivo,
      id_persona_rol_estudiante,
      id_persona_rol_retira,
    } = datos
    const {data} = await axios({
      method: 'PUT',
      url: `retiro/${id}`,
      data: {
        id_curso,
        fecha,
        motivo,
        hora_salida,
        hora_regreso,
        id_persona_rol_estudiante,
        id_persona_rol_retira,
      },
    })
    commit('setResponse', data)
  } catch (e) {
    console.log('error :', e)
    // commit('parcialError', e.response.data)
  }
}

export async function removeSalida({ commit, dispatch }, datos) {
  try {
    const {
      id,
      id_establecimiento,
    } = datos
    const { data } = await axios({
      method: 'DELETE',
      url: `/retiro/${id}`,
    })
    dispatch('fetchSalidas', id_establecimiento)
  } catch (e) {
    console.log('error remove :', e)
    // commit('observacionError', e.response.data)
  }
}

