export default {
  evaluaciones: [],
  evaluacionesSumativas: [],
  evaluacionesParaSuamtiva: [],
  evaluacionesActivos: [],
  id_evaluacion: null,
  selectedLibro: null,
  error: false,
  errorMessage: [],
  state: null,
  message: null,
}
