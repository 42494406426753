export default function personalidad() {
  return 0
}

export function getInformesPersonalidad(state) {
  return state.informesPersonalidad
}

export function getInformePersonalidad(state) {
  return state.informePersonalidad
}
